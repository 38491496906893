import React, { useState } from "react";
import { Link } from 'react-router-dom';

import * as yup from 'yup';
import Service from './../../service';
import Loading from './../loading';
import { useFormik } from 'formik';
import toast, { Toaster } from 'react-hot-toast';

import FirebaseNotifications from './../firebaseNotifications/Notification'



const api = new Service();
const Footer = (props) => {
   const [loading, setLoading] = useState(false);
   const formik = useFormik({
      initialValues: {
         email_id: '',
      },

      validationSchema: yup.object({
         email_id: yup.string().email('Invalid email address').required('Please enter your email address'),
      }),

      onSubmit: values => {
         setLoading(true);
         api.postApi('save-web-users', values).then(response => {
            if (response == true) {
               setLoading(false);
               toast.success('Added Successfully');
               formik.resetForm()
            }
         }).catch(error => {
            setLoading(false);
            toast.error(error.message);
         });
      }

   });


   return (
      <>
         <Toaster position="top-right" reverseOrder={false} />
         <footer>
            <div className="container">
               <div className="grid grid-lg">
                  <div className="unit-2-4 unit-1-1-md unit-1-1-sm unit-vertical-center margin-bottom">
                     <Link className="logo" to="/"><img src="/web/img/avif-img/logo.avif" alt="gomoveit" /></Link>
                     <nav className="columns">
                        <div><Link to="/city">Move It cities</Link></div>
                        <div><Link to="/move-it-news">Move It News</Link></div>
                        <div><Link to="/become-pro-muscle">Become a Pro & Muscle</Link></div>
                        <div><Link to="/faq-pro-muscles">Pro & Muscle FAQ</Link></div>
                        <div><Link to="/faq">FAQ</Link></div>

                        <div><Link to="/delivery-service-app">Additional Services</Link></div>
                        <div><Link to="/contact-us">Contact us</Link></div>
                        <div><Link to="/privacy-policy">Privacy policy</Link></div>
                        <div><Link to="/terms-condition">Terms & conditions</Link></div>
                        <div><Link to="/moveit-licensing ">Move It Licensing</Link></div>

                        <nav className="social">
                           <a href="https://www.facebook.com/gomoveit" target="_blank" rel="noreferrer"><img src="/web/img/facebook.png" alt="Move It Facebook" /></a>
                           <a href="https://www.instagram.com/gomoveit" target="_blank" rel="noreferrer"><img src="/web/img/instagram.png" alt="Move It Instagram" /></a>
                           <a href="https://twitter.com/gomoveit" target="_blank" rel="noreferrer"><img src="/web/img/twitter.png" style={{
                              width: "29px",
                              height: "29px"
                           }} alt="Move It Twitter" /></a>
                           <a href="https://www.youtube.com/channel/UCmgTPJqb4PnoXE47UCXSzhg" target="_blank" rel="noreferrer"><img src="/web/img/youtube.png" alt="Move It Youtube" /></a>
                           <a href="https://g.page/r/CS7NalJnzSWnEAg/review" target="_blank" rel="noreferrer"><img src="/web/img/google-plus.png" alt="Move It Google" /></a>
                           <a href="https://nextdoor.com/pages/move-it-llc-henderson-nv/recommendations/?recommend=1" rel="noreferrer" target="_blank">
                              <img src="/web/img/nextdoor.png" alt="Move It Nextdoor" /></a>
                           <a href="https://threads.net/gomoveit" rel="noreferrer" target="_blank">
                              <img src="/web/img/threads-logo.png" alt="Move It Nextdoor" /></a>
                        </nav>
                        <div><Link to="#"></Link></div>
                     </nav>
                  </div>
                  <div className="unit-2-4 unit-1-1-md unit-1-1-sm unit-vertical-center">
                     <div id="newsletter" className="grid grid-center">
                        <div className="unit-2-3 unit-1-1-md">
                           <h5 className="mb-2">Get News <b>+</b> Updates</h5>
                           <p>Stay connected with Move It. Get updates, follow us on social media for seasonal promotional codes and more!</p>
                           <form className="inline" onSubmit={formik.handleSubmit} >
                              <div className="fluid position-relative">
                                 <input type="email_id" name="email_id" placeholder="Enter your email address"  {...formik.getFieldProps("email_id")} />
                                 {formik.touched.email_id && formik.errors.email_id ? <span className="text-danger erromsg">{formik.errors.email_id}</span> : null}
                              </div>
                              <button type="submit"><i className="fa fa-chevron-right"></i></button>
                           </form>
                        </div>
                        <div className="unit-2-3 unit-1-1-md mt-4">
                           <div className="unit-1-1 achievements-awards mt-1">
                              <h5 className="mb-2" style={{ padding: "0px" }}>Achievements & Awards</h5>
                              <div className="awards-logos mb-3">
                                 <img width="300px" src="/web/img/license.png" alt="Next Door Fave" style={{ marginRight: "15px", borderRadius: "100px" }} />
                                 {/*<img width="80px" src="/web/img/BestofLasVegaswinnerlogo.jpeg" alt="Best of Las Vegas" style={{borderRadius:"100px"}}/>*/}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="legal">
               <div className="grid">
                  <div className="unit-1-1 unit-1-1-sm text-center">
                     <p>© 2025 Move It All Rights Reserved.</p>
                  </div>
               </div>
            </div>
         </footer>
         <Loading loading={props.loading == true ? true : false} />
         <FirebaseNotifications />
      </>
   )
}
export default Footer;